import ErrorPage from '@/components/ErrorPage'
import { PlpResponse } from '@/types'
import { getAgentConfiguration, isLanguageValid } from '@/helpers/UrlHelper'
import PlpPageTemplate from '@/components/PlpPageTemplate'
import { homeEntityDetails } from '@/helpers/FakeApiHelper'

type HomePageProps = {
  homeResponse: PlpResponse
  canonicalLink: string
}

export default function HomePage({ homeResponse }: HomePageProps) {
  if (homeResponse.error) {
    return <ErrorPage />
  }

  if (homeResponse.entityType === 'HOME') {
    const { agentConfiguration, entityType, templateDefinition } = homeResponse
    return (
      <PlpPageTemplate
        agentConfiguration={agentConfiguration}
        entityDetails={homeEntityDetails}
        entityType={entityType}
        templateDefinition={templateDefinition}
        canonicalLink=""
      />
    )
  }
}

export async function getServerSideProps(context: {
  query: { lang: string; part1: string }
  req: { headers: { host: string } }
}) {
  const { lang } = context.query
  if (!isLanguageValid(lang)) return { notFound: true }
  const host = context.req.headers.host
  const agentConfiguration = getAgentConfiguration(host)
  const query = new URLSearchParams({ ...agentConfiguration })

  const response = await fetch(`${process.env.NEXT_PUBLIC_LOKI_BASE_URL}/storefront-resources/catalogue?${query}`, {
    method: 'GET',
  })
  if (!response.ok) return { props: { homeResponse: { error: true } } }
  const catalogueResponse: PlpResponse = await response.json()

  return { props: { homeResponse: catalogueResponse } }
}
